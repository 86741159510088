var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin" }, [
    _c(
      "div",
      { staticClass: "center" },
      [
        !_vm.inputConfirmCode
          ? [
              _c("h1", [_vm._v("確認コードを再送する")]),
              _vm._m(0),
              _vm.message
                ? _c("div", {
                    staticClass: "message",
                    domProps: { innerHTML: _vm._s(_vm.message) },
                  })
                : _vm._e(),
              _c("text-field", {
                attrs: { type: "email", label: "登録メールアドレス" },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _c(
                "button",
                {
                  staticClass: "enrollment-button",
                  attrs: { disabled: !_vm.email },
                  on: { click: _vm.resend },
                },
                [_vm._v("確認メールを再送する")]
              ),
              _c(
                "div",
                {
                  staticClass: "error-message",
                  attrs: { "v-if": _vm.errorMessage },
                },
                [_vm._v(_vm._s(_vm.errorMessage))]
              ),
            ]
          : _c("sign-up-confirm-code", {
              attrs: { email: _vm.email },
              on: { complete: _vm.complete },
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "description" }, [
      _c("div", [_vm._v("Nolaに登録したメールアドレスを入力してください。")]),
      _c("div", [_vm._v("登録に必要な確認コードを再送信します。")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }