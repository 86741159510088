var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signup" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "div",
          { staticClass: "center" },
          [
            _c("router-link", { attrs: { to: { name: "top" } } }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("@/assets/img/logo.png") },
              }),
            ]),
          ],
          1
        ),
        _c("sign-up-form"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }