var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "sign-in-form" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("h1", [_vm._v("Nola関連アカウントで登録")]),
        _c("div", { staticClass: "description" }, [
          _vm._v(
            " Nola関連サービス（Nolaノベル、Nola出版など）でご登録いただいているアカウントを使って「Nola」もご利用いただけます。同一アカウントを利用することで、データの連携などができ、スムーズな投稿ができるようになる予定です。 "
          ),
        ]),
        _c("text-field", {
          staticClass: "input",
          attrs: { type: "email", label: "メールアドレス" },
          model: {
            value: _vm.email,
            callback: function ($$v) {
              _vm.email = $$v
            },
            expression: "email",
          },
        }),
        _c("text-field", {
          staticClass: "input",
          attrs: { type: "password", label: "パスワード" },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v
            },
            expression: "password",
          },
        }),
        _c("div", { staticClass: "attention" }, [
          _vm._v("※ パスワードは7文字以上かつ大文字英字が1つ以上必須です。"),
        ]),
        _c(
          "div",
          { staticClass: "sign-in-container" },
          [
            _vm._m(0),
            _c("button-default", { staticClass: "login-button" }, [
              _vm._v("ログイン"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm.message
      ? _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "sign-in-txt" }, [
      _vm._v(" 新規会員登録をすることで「Nola」の "),
      _c("a", { attrs: { href: "/terms", target: "blank" } }, [
        _vm._v("利用規約"),
      ]),
      _vm._v("と"),
      _c("a", { attrs: { href: "/privacy", target: "blank" } }, [
        _vm._v("プライバシーポリシー"),
      ]),
      _vm._v(" に同意したものとみなされます。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }