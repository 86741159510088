var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "sign-up-confirm-code" },
    [
      _c("b", [_vm._v("メールの6桁の確認コードを入れてください")]),
      _c("br"),
      _vm.message
        ? _c("div", {
            staticClass: "message",
            domProps: { innerHTML: _vm._s(_vm.message) },
          })
        : _vm._e(),
      _c("text-field", {
        staticClass: "number",
        attrs: { label: "確認コード（6桁）", type: "numbet", maxlength: 6 },
        model: {
          value: _vm.confirmCode,
          callback: function ($$v) {
            _vm.confirmCode = $$v
          },
          expression: "confirmCode",
        },
      }),
      _c(
        "button",
        {
          staticClass: "enrollment-button",
          attrs: { disabled: _vm.confirmCode.length !== 6 },
          on: { click: _vm.submitConfirmCode },
        },
        [_vm._v(" 確認コードを登録 ")]
      ),
      _c(
        "button",
        {
          staticClass: "enrollment-button",
          attrs: { disabled: !_vm.email },
          on: { click: _vm.resend },
        },
        [_vm._v("確認メールを再送する")]
      ),
      _c("div", { staticClass: "attention" }, [
        _vm._v(" ※ 確認コードが届かない方は"),
        _c(
          "a",
          {
            attrs: {
              href: `${_vm.help}#a9ab790429a64fae9489d2ac06671565`,
              target: "blank",
            },
          },
          [_vm._v("こちら")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "error-message", attrs: { "v-if": _vm.errorMessage } },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }