var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "sign-up-form" },
    [
      !_vm.inputConfirmCode
        ? _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("h1", [_vm._v("アカウントを作成してはじめましょう")]),
              _c("div", { staticClass: "description" }, [
                _vm._v(
                  " Nolaは、会員登録をしていただくことで制作データをクラウドで管理し、PC・スマホなどお好きなデバイスでご利用いただける執筆ツールとなっております。 "
                ),
              ]),
              _c("div", { staticClass: "warning" }, [
                _c("img", {
                  staticClass: "warning-img",
                  attrs: { src: require("@/assets/img/static/attention.png") },
                }),
                _c(
                  "div",
                  [
                    _vm._v(
                      " Nola関連サービス（Nolaノベル、Nola出版など）で、すでにアカウントをご登録いただいている方は "
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: { name: "signInConnectedAccount" } } },
                      [_vm._v("こちら")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm.message
                ? _c("div", { staticClass: "message" }, [
                    _vm._v(_vm._s(_vm.message)),
                  ])
                : _vm._e(),
              _vm.errorMessage
                ? _c("div", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errorMessage)),
                  ])
                : _vm._e(),
              _c("text-field", {
                staticClass: "input",
                attrs: {
                  type: "email",
                  name: "email",
                  label: "登録メールアドレス",
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _c("text-field", {
                staticClass: "input",
                attrs: { type: "password", label: "パスワード" },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
              _c("text-field", {
                staticClass: "input",
                attrs: { type: "password", label: "パスワード（確認用）" },
                model: {
                  value: _vm.passwordConfirm,
                  callback: function ($$v) {
                    _vm.passwordConfirm = $$v
                  },
                  expression: "passwordConfirm",
                },
              }),
              _c("div", { staticClass: "attention" }, [
                _vm._v(
                  "※ パスワードは7文字以上かつ大文字英字が1つ以上必須です。"
                ),
              ]),
              _c("div", { staticClass: "attention" }, [
                _vm._v(" ※ 確認コードが届かない方は"),
                _c(
                  "a",
                  {
                    attrs: {
                      href: `${_vm.help}#a9ab790429a64fae9489d2ac06671565`,
                      target: "blank",
                    },
                  },
                  [_vm._v("こちら")]
                ),
              ]),
              _c(
                "div",
                { staticClass: "attention" },
                [
                  _vm._v(" ※ TwitterログインでNolaをご利用いただいていた方は "),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "signInTwitterAccount" } } },
                    [_vm._v("こちら")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "sign-up-container" },
                [
                  _vm._m(0),
                  _c("button-default", { staticClass: "enrollment-button" }, [
                    _vm._v("新規会員登録する"),
                  ]),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "resendConfirmCode",
                          params: { propEmail: _vm.email },
                        },
                      },
                    },
                    [_vm._v(" 確認コードを再送する ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("sign-up-confirm-code", {
            attrs: { email: _vm.email },
            on: { complete: _vm.complete },
          }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "sign-up-txt" }, [
      _vm._v(" 新規会員登録をすることで「Nola」の "),
      _c("a", { attrs: { href: "/terms", target: "blank" } }, [
        _vm._v("利用規約"),
      ]),
      _vm._v("と"),
      _c("a", { attrs: { href: "/privacy", target: "blank" } }, [
        _vm._v("プライバシーポリシー"),
      ]),
      _vm._v(" に同意したものとみなされます。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }