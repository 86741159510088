var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "confirm-email" }, [
    _c(
      "div",
      { staticClass: "center" },
      [
        _c("h1", [_vm._v("メールアドレスの変更を確定する")]),
        _c("p", [_vm._v("パスワードを入力して変更を確定して下さい。")]),
        _c("text-field", {
          attrs: { label: "パスワード", type: "password" },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v
            },
            expression: "password",
          },
        }),
        _c("div", { staticClass: "attention" }, [
          _vm._v("※ パスワードは7文字以上かつ大文字英字が1つ以上必須です"),
        ]),
        _c(
          "button",
          {
            staticClass: "enrollment-button",
            attrs: { disabled: _vm.password.length < 7 },
            on: { click: _vm.confirmEmail },
          },
          [_vm._v("変更を確定する")]
        ),
        _c(
          "div",
          { staticClass: "error-message", attrs: { "v-if": _vm.errorMessage } },
          [_vm._v(_vm._s(_vm.errorMessage))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }