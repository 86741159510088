var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "resetPassword" }, [
    _c("div", { staticClass: "center" }, [
      _c("h1", [_vm._v("パスワードの再設定")]),
      _vm._m(0),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("text-field", {
                attrs: { label: "メールアドレス" },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v
                  },
                  expression: "username",
                },
              }),
            ],
            1
          ),
          _c(
            "button-default",
            { attrs: { disable: !_vm.username || _vm.submitting } },
            [_vm._v("メール送信")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.message,
              expression: "message",
            },
          ],
          staticClass: "warning",
        },
        [_vm._v(_vm._s(_vm.message))]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v(
        " 登録済みのメールアドレスを入力して、送信ボタンを押してください。"
      ),
      _c("br"),
      _vm._v(
        " ご記入いただいたメールアドレスへパスワードの再設定のご案内を送付します。"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        " なお、変更を行うとNola関連サービス全てのパスワードが変更されます。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }