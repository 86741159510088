var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "sign-in-form" }, [
    _c("h1", [_vm._v("Twitterログインをご利用の方へ")]),
    _vm._m(0),
    _c("div", { staticClass: "sign-in-container" }, [
      _vm._m(1),
      _c(
        "button",
        {
          staticClass: "button twitter-login",
          on: { click: _vm.submitTwitterAuth },
        },
        [
          _c("img", {
            staticClass: "twi-img",
            attrs: { src: require("@/assets/img/static/twi.png") },
          }),
          _c("div", { staticClass: "twi-txt" }, [_vm._v("Twitterでログイン")]),
        ]
      ),
      _c("button", { staticClass: "button", on: { click: _vm.backToSignIn } }, [
        _vm._v("ログイン画面に戻る"),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "warning" }, [
      _c("img", {
        staticClass: "warning-img",
        attrs: { src: require("@/assets/img/static/attention.png") },
      }),
      _c("div", [
        _vm._v(
          " 2019年6月15日に「Nola」はメールアドレス登録版に移行しました。 旧ログイン方式のTwitterログインでNolaをご利用いただいていた方は、以下のボタンよりログインしてメールアドレスを登録してください。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "sign-in-txt" }, [
      _vm._v(" Nolaにログインすることで「Nola」の "),
      _c("a", { attrs: { href: "/terms", target: "blank" } }, [
        _vm._v("利用規約"),
      ]),
      _vm._v("と"),
      _c("a", { attrs: { href: "/privacy", target: "blank" } }, [
        _vm._v("プライバシーポリシー"),
      ]),
      _vm._v(" に同意したものとみなされます。 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }